export const useHotSpot = (hotSpotData: any) => {
  const selectedPointIndex = ref(-1)
  const imgWidth = ref(0)
  const imgHeight = ref(0)
  const handleSelectedPoint = (index: number) => {
    selectedPointIndex.value = index
  }

  const handleBackBtn = () => {
    if (selectedPointIndex.value < 1) {
      selectedPointIndex.value = hotSpotData.hotspots.length - 1
    } else {
      selectedPointIndex.value--
    }
  }

  const handleForwardBtn = () => {
    if (selectedPointIndex.value === hotSpotData.hotspots.length - 1) {
      selectedPointIndex.value = 0
    } else {
      selectedPointIndex.value++
    }
  }

  const checkImageSize = (imgEl: HTMLImageElement) => {
    if (imgEl) {
      requestAnimationFrame(() => {
        imgWidth.value = imgEl.width
        imgHeight.value = imgEl.height
      })
    }
  }
  return {
    imgWidth,
    imgHeight,
    checkImageSize,
    selectedPointIndex,
    handleSelectedPoint,
    handleBackBtn,
    handleForwardBtn,
  }
}
