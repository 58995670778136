/**
 * Validates that a given value is numeric
 *
 * @param n Value to check if is numeric
 */
export function isNumeric(n: any) {
  return !isNaN(parseFloat(n)) && isFinite(n)
}

/**
 * Function checks if a value is within a given range
 *
 * @param v Value to check if in range
 * @param min Min value of range
 * @param max Max value of range
 */
export function inRange(v: number, min: number, max: number): boolean {
  return v >= min && v <= max
}

/**
 * Generates a random number, between two numbers.
 *
 * @param min Minimum value allowed
 * @param max Maximum value allowed
 */
export function randomInt(min: number, max: number): number {
  min = Math.ceil(min)
  return Math.floor(Math.random() * (Math.floor(max) - min + 1) + min)
}

/**
 * Changes a given number into a fraction.
 *
 * @param n Value wished changed to a string of the fraction.
 * @param p Precision of the denominator.
 */
export function toFraction(n: number, p: number = 3): string {
  const integerPart = Math.floor(n)

  if (n - integerPart === 0) {
    return integerPart.toString() // If there's no fractional part
  }

  const numerator = Math.round((n - integerPart) * p)
  return (
    (integerPart > 0 ? integerPart : '') +
    `<sup>${numerator / gcd(numerator, p)}</sup>&frasl;<sub>${p / gcd(numerator, p)}</sub>`
  )
}
