<script setup lang="ts">
  import ParagraphListDefault from '../../Partials/ParagraphList/ParagraphListDefault.vue'
import ParagraphListTimeline from '../../Partials/ParagraphList/ParagraphListTimeline.vue';
  import ParagraphListUnordered from '../../Partials/ParagraphList/ParagraphListUnordered.vue';

  // TODO : Make components for each of these. Defaults to the same right now.
  const componentMap: ComponentMap = {
    ordered: ParagraphListDefault,
    unordered: ParagraphListUnordered,
    image: ParagraphListDefault,
    custom: ParagraphListTimeline,
    default: ParagraphListDefault,
  }

  const paragraphListData = defineProps<{
    data: any
  }>()

  const selectedComponent = computed(
    () => componentMap[paragraphListData.data.display_hint] ?? ParagraphListDefault
  )
</script>

<template>
  <section class="center-content cXenseParse">
    <component
      :is="selectedComponent"
      :paragraphListData="paragraphListData.data"
    />
  </section>
</template>
