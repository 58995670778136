<script setup lang="ts">
  import NotSupported from '../../ui/notSupported.vue'
  import InsertedCode from './InsertedCode.vue'
  const { data } = defineProps<{
    data: any
  }>()

  let multiMediaData = data
  const type = multiMediaData.type
  const { display_hint } = multiMediaData

  const componentMap: ComponentMap = {
    sketchfab: InsertedCode,
    thing_link: InsertedCode,
    genially: InsertedCode,
  }

  const selectedDisplay = (componentName: keyof ComponentMap) => {
    const display = componentMap[componentName]

    if (!display) {
      return {
        display: NotSupported,
        props: { widgetName: type, display_hint: componentName },
      }
    }

    return { display }
  }
</script>

<template>
  <!-- TODO: adjust to new grid system -->
  <section class="center-content">
    <component
      :is="selectedDisplay(display_hint).display"
      v-bind="selectedDisplay(display_hint).props"
      :data="multiMediaData"
    />
  </section>
</template>
