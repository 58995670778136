<script setup lang="ts">
  import type { TableData } from '../../../types/widgets/composites/tableTypes';
import TableDefault from '../../Partials/Table/TableDefault.vue'

  const componentMap: ComponentMap = {
    default: TableDefault,
  }

  const tableData = defineProps<{
    data: TableData
  }>()

  const selectedComponent = computed(
    () => componentMap[tableData.data.display_hint ?? 'default']
  )
</script>

<template>
  <section>
    <component
      :is="selectedComponent"
      class="cXenseParse"
      :tableData="tableData?.data"
    />
  </section>
</template>
