<script setup lang="ts">
  import LeadParagraphDefault from '../../Partials/LeadParagraph/LeadParagraphDefault.vue'
  import LeadParagraphChapter from '../../Partials/LeadParagraph/LeadParagraphChapter.vue'
  import type { LeadParagraphData } from '../../../types/widgets/composites/LeadParagraphTypes'

  const componentMap: ComponentMap = {
    default: LeadParagraphDefault,
    chapter: LeadParagraphChapter,
  }

  const leadParagraphData = defineProps<{
    data: LeadParagraphData
  }>()

  const selectedComponent = computed(
    () => componentMap[leadParagraphData.data.display_hint] ?? LeadParagraphDefault
  )
</script>

<template>
  <section
    class="center-content cXenseParse"
    data-component-type="lead paragraph"
    data-component-name="lead paragraph"
  >
    <component
      :is="selectedComponent"
      :leadParagraphData="leadParagraphData.data"
    />
  </section>
</template>
