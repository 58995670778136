<script setup lang="ts">
  import GalleryDefault from '../../Partials/Gallery/GalleryDefault.vue'
  import GalleryParallax from '../../Partials/Gallery/GalleryParallax.vue'
  import GalleryInline from '../../Partials/Gallery/GalleryInline.vue'

  const componentMap: ComponentMap = {
    default: GalleryDefault,
    parallax: GalleryParallax,
    inline: GalleryInline,
  }

  const galleryData = defineProps<{
    data: GalleryData
  }>()

  const selectedComponent = computed(
    () => componentMap[galleryData.data[componentMapProp]] ?? GalleryDefault
  )
</script>

<template>
  <component :is="selectedComponent" :galleryData="galleryData.data" />
</template>
