<script setup lang="ts">
  import type { HotSpotImageType } from '../../../types/widgets/composites/HotSpotImageTypes'
  import PartialsHotSpotImageDefault from '../../Partials/HotSpotImage/HotSpotImageDefault.vue'
  import PartialsHotSpotImageUnordered from '../../Partials/HotSpotImage/HotSpotImageUnordered.vue'
  const { data } = defineProps<{
    data: HotSpotImageType
  }>()

  const componentMap: ComponentMap = {
    default: PartialsHotSpotImageDefault,
    ordered: PartialsHotSpotImageDefault,
    unordered: PartialsHotSpotImageUnordered,
  }

  const selectedComponent = computed(() => componentMap[data?.display_hint] ?? PartialsHotSpotImageDefault)
</script>
<template>
  <component :is="selectedComponent" :hotSpotData="data" />
</template>
