<script setup lang="ts">
  const { data } = defineProps<{
    data: {
      title: string
      description: string
      file: object
      download_button_text: string
    }
  }>()

  const trackDownload = () => {
    // TODO - Add Tracking
  }
</script>

<template>
  <div v-if="data.file" class="bg-tone-secondary flex flex-col gap-4 px-4 py-6">
    <h2 v-if="data.title" class="font-bold-700 font-article-medium">
      {{ data.title }}
    </h2>
    <p v-if="data.description" class="font-body">{{ data.description }}</p>
    <a
      class="bg-button-primary border-small border-tone-primary w-fit p-4 uppercase"
      :href="data.file.url"
      @click="trackDownload()"
      >{{ data.download_button_text ?? 'Download Her' }}</a
    >
  </div>
</template>
