<script setup lang="ts">
  const { tableData } = defineProps<{
    tableData: TableData
  }>()

  const hasAlternatingRowColor = computed(
    () =>
      tableData?.table_data?.raw_data?.options?.alternating_row_colors ?? false
  )

  const rowHover = computed(
    () => tableData?.table_data?.raw_data?.options?.row_hover ?? false
  )
</script>

<template>
  <div class="grid__item--content">
    <div class="table-container">
      <div
        class="table-press"
        :class="{
          'alternating-row-color': hasAlternatingRowColor,
          'row-hover': rowHover,
        }"
        v-html="tableData.table_data?.html"
      />
    </div>
  </div>
</template>
