<script setup lang="ts">
  import LinkTo from '../../LinkTo.vue'
  import SvgIcon from '../../SvgIcon.vue'

  const { data } = defineProps<{
    data: any
  }>()

  const translations = useTranslationStore()

  const targetAttr = computed(() => {
    if (data.target === 'Blank') {
      return '_blank'
    }
    return '_self'
  })
</script>

<template>
  <div class="flex gap-4">
    <SvgIcon name="arrow-alt" class="h-6" />
    <span class="font-bold">{{
      translations.getTranslation('components.widgets.read_more')
    }}</span>
    <LinkTo
      :link="data.url"
      :target="targetAttr"
      :title="data.title"
      class="underline"
    >
      {{ data.title }}
    </LinkTo>
  </div>
</template>
